import { template as template_0496f74a9cf940f1902dc63100e1b437 } from "@ember/template-compiler";
const FKControlMenuContainer = template_0496f74a9cf940f1902dc63100e1b437(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
