import { template as template_4f8e40bf76194f08b81b997fe82a9b27 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_4f8e40bf76194f08b81b997fe82a9b27(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
