import { template as template_f65706a467e0488298207192423d7a81 } from "@ember/template-compiler";
const EmptyState = template_f65706a467e0488298207192423d7a81(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
